import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { ActionTable } from '../../interfaces/actionTable';

@Component({
  selector: 'app-table-layout',
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.css']
})
export class TableLayoutComponent implements OnInit {

  @Input() actionTable: ActionTable = { title: "", subTitle: "", buttons: [] };
  @Input() dataList: any = [];

  pageSize = 10;
  currentPage = 1;
  collectionSize = 100;

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;

  @ViewChild('dataTable') dataTable!: DatatableComponent;

  constructor() { }

  ngOnInit() {
  }


}
