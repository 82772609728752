import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './components/loader/loader.component';
import { MenuComponent } from './components/menu/menu.component';
import { ContentLayoutComponent } from './components/content-layout/content-layout.component';
import { RouterModule } from '@angular/router';
import { TableLayoutComponent } from './components/table-layout/table-layout.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { PaginationComponent } from './components/pagination/pagination.component';
import { InputCalendarComponent } from './components/input-calendar/input-calendar.component';
import { ActionToastrComponent } from './components/action-toastr/action-toastr.component';
import { CustomToastrComponent } from './components/custom-toastr/custom-toastr.component';



@NgModule({
  declarations: [
    LoaderComponent,
    ActionToastrComponent,
    CustomToastrComponent,
    MenuComponent,
    ContentLayoutComponent,
    InputCalendarComponent,
    TableLayoutComponent,
    PaginationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxDatatableModule,
  ],
  exports: [
    LoaderComponent,
    ContentLayoutComponent,
    MenuComponent,
    InputCalendarComponent,
    TableLayoutComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
