import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  public isMenuOpen: Boolean = false;
  public isDropdownOpen: Boolean = false;

  public isCompanyOpen: Boolean = false;
  public companyExpand: String = 'expand_more';

  public isProductsOpen: Boolean = false;
  public productsExpand: String = 'expand_more';

  public isProceduresOpen: Boolean = false;
  public proceduresExpand: String = 'expand_more';

  constructor(
    public router: Router,
  ) { }

  ngOnInit() {
  }

  toggleCompany() {
    this.isCompanyOpen = !this.isCompanyOpen;
    this.companyExpand = this.isCompanyOpen ? 'expand_less' : 'expand_more';
  }

  toggleProducts() {
    this.isProductsOpen = !this.isProductsOpen;
    this.productsExpand = this.isProductsOpen ? 'expand_less' : 'expand_more';
  }

  toggleProcedures() {
    this.isProceduresOpen = !this.isProceduresOpen;
    this.proceduresExpand = this.isProceduresOpen ? 'expand_less' : 'expand_more';
  }

  onCallIndex() {
    this.router.navigateByUrl(`/`);
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    console.log("Menu toggled", this.isMenuOpen);
  }


  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }
}
