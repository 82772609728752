import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-calendar',
  templateUrl: './input-calendar.component.html',
  styleUrls: ['./input-calendar.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',
        opacity: 1,
        display: 'block'
      })),
      state('closed', style({
        height: '0px',
        opacity: 0,
        display: 'none'
      })),
      transition('open <=> closed', animate('0.2s'))
    ])
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputCalendarComponent,
      multi: true
    }
  ]
})
export class InputCalendarComponent implements ControlValueAccessor {
  @Input() public disabled: boolean = false;
  @Input() public label: String | undefined;
  @Input() public multiselect: boolean = false;
  @Input() public initialDate: string = '';
  @Input() public dateTime: boolean = false;
  @Output() emitDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() dateChange = new EventEmitter<string>();
  @Input() nameInput!: string;

  @ViewChild('calendarInput')
  calendarInput!: ElementRef;

  months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  years: number[] = [];
  selectedMonth: number = new Date().getMonth();
  selectedYear: number = new Date().getFullYear();
  weekDays = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
  weeks: (number | null)[][] = [];
  selectedDate: Date | null = null;
  isOpened = false; // Controla el estado de apertura del calendario
  selectedStartDate: Date | null = null;
  selectedEndDate: Date | null = null;
  isOpenYear = false; // Inicialmente cerrado
  isOpenMonth = false; // Inicialmente cerrado
  selectedTime: any = { hour: 0, minute: 0, second: 0 };
  private onTouched!: () => void;
  private onChanged!: () => void;

  constructor(private elRef: ElementRef) {
    this.generateYears();
    this.generateCalendar();
  }

  /* Interface ControlValueAccessor implementation  */
  writeValue(obj: any): void {
    // Metodo a configurar para la actualización de datos.
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleSelectStateYear(state: boolean): void {
    this.isOpenYear = state; // Cambia el estado de isOpen según el evento
  }
  toggleSelectStateMonth(state: boolean): void {
    this.isOpenMonth = state; // Cambia el estado de isOpen según el evento
  }

  ngAfterViewInit(): void {
    this.setInputDate(this.initialDate);
  }

  selectDay(day: number | null, weekIndex: number, dayIndex: number): void {
    if (day === null || this.disabled) return;

    const dateOfCell = this.calculateDateOfCell(day, weekIndex, dayIndex);
    if (this.multiselect) {
      // Logica para seleccionar un rango de fechas
      if (!this.selectedStartDate || (this.selectedStartDate && this.selectedEndDate)) {
        this.selectedStartDate = dateOfCell;
        this.selectedEndDate = null; // Reset end date if starting a new range
      } else if (this.selectedStartDate && !this.selectedEndDate) {
        if (dateOfCell >= this.selectedStartDate) {
          this.selectedEndDate = dateOfCell;
        } else {
          this.selectedEndDate = this.selectedStartDate;
          this.selectedStartDate = dateOfCell;
        }
        this.emitDate.emit({ start: this.selectedStartDate, end: this.selectedEndDate });
      }
    } else {
      // Logica para seleccionar una sola fecha
      this.selectedStartDate = dateOfCell;
      this.selectedEndDate = null;
      this.emitDate.emit({ start: this.selectedStartDate, end: this.selectedEndDate });
    }

    this.updateInput();
  }

  updateInput(): void {
    const inputElement = this.calendarInput.nativeElement as HTMLInputElement;
    if (this.multiselect && this.selectedStartDate && this.selectedEndDate) {
      const formattedStart = this.formatDateForInput(this.selectedStartDate);
      const formattedEnd = this.formatDateForInput(this.selectedEndDate);
      inputElement.value = `${formattedStart} - ${formattedEnd}`;
      this.dateChange.emit(inputElement.value);
    } else if (this.selectedStartDate) {
      inputElement.value = this.formatDateForInput(this.selectedStartDate);
      this.dateChange.emit(inputElement.value);
    } else {
      inputElement.value = '';
      this.dateChange.emit('');
    }
  }

  toggleCalendar(event: MouseEvent): void {
    this.isOpened = !this.isOpened;
    event.stopPropagation();  // Evita que el evento de clic se propague

  }
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    // Cerrar el calendario si el clic fue fuera del elemento del calendario
    if (!this.elRef.nativeElement.contains(targetElement) && this.isOpened) {
      this.isOpened = false;
    }

    // Cerrar el select si el clic fue fuera del select
    if (!this.elRef.nativeElement.contains(targetElement) && this.isOpenYear) {
      this.isOpenYear = false;
    }

    if (!this.elRef.nativeElement.contains(targetElement) && this.isOpenMonth) {
      this.isOpenMonth = false;
    }

  }

  setInputDate(initialDate: string): void {
    const inputElement = this.calendarInput.nativeElement as HTMLInputElement;

    if (!this.multiselect) {
      if (this.dateTime) {
        const time = initialDate.split(' ')[1];
        initialDate = initialDate.split(' ')[0];
        const pivot = time.split('.')[0].split(':');
        this.selectedTime = { hour: parseInt(pivot[0]), minute: parseInt(pivot[1]), second: parseInt(pivot[2]) };
      }

      const [day, month, year] = initialDate.split('/').map(part => parseInt(part, 10));
      if (initialDate) {
        this.selectedStartDate = new Date(year, month, day);
        inputElement.value = this.formatDateForInput(this.selectedStartDate);
        this.dateChange.emit(inputElement.value);
      } else {
        inputElement.value = '';
        this.dateChange.emit('');
      }
    }
    else if (this.multiselect) {
      if (initialDate.split('-').length > 1) {
        const [start, end] = initialDate.split('-');
        inputElement.value = `${start} - ${end}`;
        this.dateChange.emit(inputElement.value);
      }
      else {
        inputElement.value = '';
        this.dateChange.emit('');
      }
    }
  }

  formatDate(date: Date | null, endDate: Date | null = null): string {
    if (!date) return '';  // Regresa un string vacío si la fecha es nula

    // Función auxiliar para formatear una fecha individual
    const formatSingleDate = (d: Date) => `${(d.getDate())} ${this.months[d.getMonth()]}, ${d.getFullYear()}`;

    // Determinar si se debe mostrar un rango o una fecha única
    if (this.multiselect && endDate) {
      return `Del ${formatSingleDate(date)} al ${formatSingleDate(endDate)}`;
    } else {
      return formatSingleDate(date);
    }
  }



  formatDateForInput(date: Date): string {
    let day = date.getDate().toString().padStart(2, '0');
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  isSelectedDay(day: number | null, weekIndex: number, dayIndex: number): boolean {
    if (day === null) return false;

    const dateOfCell = this.calculateDateOfCell(day, weekIndex, dayIndex);
    if (this.multiselect && this.selectedStartDate && this.selectedEndDate) {
      return (dateOfCell >= this.selectedStartDate && dateOfCell <= this.selectedEndDate) ?? false;
    }
    return (this.selectedStartDate && dateOfCell.toDateString() === this.selectedStartDate.toDateString()) ?? false;
  }


  isToday(day: number | null, weekIndex: number, dayIndex: number): boolean {
    if (!day) {
      return false;
    }
    const today = new Date();
    const dateOfCell = this.calculateDateOfCell(day, weekIndex, dayIndex);
    return dateOfCell.toDateString() === today.toDateString();
  }

  calculateDateOfCell(day: number, weekIndex: number, dayIndex: number): Date {
    const firstCalendarDay = new Date(this.selectedYear, this.selectedMonth, 1);
    firstCalendarDay.setDate(firstCalendarDay.getDate() - firstCalendarDay.getDay());
    const dateOfCell = new Date(firstCalendarDay);
    dateOfCell.setDate(firstCalendarDay.getDate() + (weekIndex * 7) + dayIndex);
    return dateOfCell;
  }


  generateYears() {
    let currentYear = new Date().getFullYear();
    for (let i = currentYear - 5; i <= currentYear + 5; i++) {
      this.years.push(i);
    }
  }

  generateCalendar() {
    // Limpia las semanas actuales para forzar un recalculado completo
    this.weeks = [];
    let days: (number | null)[] = [];
    const daysInMonth = new Date(this.selectedYear, this.selectedMonth + 1, 0).getDate();

    const firstDayOfMonth = new Date(this.selectedYear, this.selectedMonth, 1).getDay();

    // Días del mes anterior para completar la primera semana
    const prevMonthDays = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
    for (let i = prevMonthDays - firstDayOfMonth + 1; i <= prevMonthDays; i++) {
      days.push(i);
    }

    // Días del mes actual
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(i);
    }

    // Días del siguiente mes para completar la última semana
    let daysToAdd = 7 - (days.length % 7);
    if (daysToAdd < 7) {
      for (let i = 1; i <= daysToAdd; i++) {
        days.push(i);
      }
    }

    // Organizar los días en semanas
    for (let i = 0; i < days.length; i += 7) {
      this.weeks.push(days.slice(i, i + 7));
    }


  }


  isOtherMonthDay(day: number | null, weekIndex: number): boolean {
    if (day === null) {
      return false;
    }

    // Obteniendo la fecha del primer día visible en el calendario.
    let firstCalendarDay = new Date(this.selectedYear, this.selectedMonth, 1);
    firstCalendarDay.setDate(firstCalendarDay.getDate() - firstCalendarDay.getDay()); // Ajusta al primer día de la semana.

    // Calcula la fecha del día específico en la grilla del calendario.
    let dateOfCell = new Date(firstCalendarDay);
    dateOfCell.setDate(firstCalendarDay.getDate() + (weekIndex * 7) + this.weeks[weekIndex].indexOf(day));

    // Verifica si el mes del día específico es diferente al mes seleccionado.
    return dateOfCell.getMonth() !== this.selectedMonth;
  }

  // Función para manejar el cambio de mes, podría incluir lógica adicional si es necesario.
  handleMonthChange(event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const newMonth = parseInt(selectElement.value, 10);
    this.selectedMonth = newMonth; // Asigna el nuevo mes seleccionado
    this.generateCalendar(); // Regenera el calendario

    this.isOpenMonth = false; // Cierra el select
    this.isOpenMonth = false; // Cierra el select
    this.toggleSelectStateMonth(false);
    selectElement.blur();  // Esto asegura que el select pierda el foco

  }

  handleYearChange(event: any): void {
    const selectElement = event.target as HTMLSelectElement;
    const newYear = parseInt(selectElement.value, 10);
    this.selectedYear = newYear; // Asigna el nuevo año seleccionado
    this.generateCalendar(); // Regenera el calendario

    this.isOpenYear = false; // Cierra el select
    this.toggleSelectStateYear(false);
    selectElement.blur(); // Esto asegura que el select pierda el foco
  }





  navigate(monthStep: number) {
    this.selectedMonth += monthStep;
    if (this.selectedMonth < 0) {
      this.selectedYear--;
      this.selectedMonth = 11;
    } else if (this.selectedMonth > 11) {
      this.selectedYear++;
      this.selectedMonth = 0;
    }
    this.generateCalendar();
  }

  isRangeDay(day: number | null, weekIndex: number, dayIndex: number): boolean {
    if (day === null || !this.selectedStartDate || !this.selectedEndDate) {
      return false;
    }
    const dateOfCell = this.calculateDateOfCell(day, weekIndex, dayIndex);
    // Verifica si el día está dentro del rango pero no es ni el inicio ni el fin
    return dateOfCell > this.selectedStartDate && dateOfCell < this.selectedEndDate;
  }

  isStartOrEndDay(day: number | null, weekIndex: number, dayIndex: number): boolean {
    if (day === null || (!this.selectedStartDate && !this.selectedEndDate)) {
      return false;
    }
    const dateOfCell = this.calculateDateOfCell(day, weekIndex, dayIndex);
    // Verifica si el día es el inicio o el fin del rango
    return dateOfCell.getTime() === this.selectedStartDate?.getTime() || dateOfCell.getTime() === this.selectedEndDate?.getTime();
  }

}
