<div class="grid md:grid-cols-3 lg:grid-cols-6 xl:grid-cols-12 items-center gap-4">

    <div class="md:col-span-1 lg:col-span-2 xl:col-span-2 ">
        <div class="relative inline-block text-end border border-text50 rounded-md p-2">
            <button (click)="toggleDropdown()" class="flex items-center space-x-3">
                <span class="font-h4 font-medium text-text90 text-nowrap">{{ pageSize }} items por página</span>
                <button
                    class="w-[40px] h-[40px] rounded-md bg-text30 text-primary75 hover:border-primaryDark hover:text-primaryDark flex items-center justify-center">
                    <span class="text-[20px] material-symbols-outlined">expand_more</span>
                </button>
            </button>

            <div *ngIf="isDropdownOpen" class="absolute right-0 mt-3 w-60 bg-white rounded-lg shadow-lg">
                <ul>
                    <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" (click)="changeItems(5)">5 items por página
                    </li>
                    <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" (click)="changeItems(10)">10 items por página
                    </li>
                    <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" (click)="changeItems(25)">25 items por página
                    </li>
                    <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" (click)="changeItems(50)">50 items por página
                    </li>
                    <li class="px-4 py-2 hover:bg-gray-100 cursor-pointer" (click)="changeItems(100)">100 items por
                        página
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="md:col-span-1 lg:col-span-2 xl:col-span-2">
        <div class="h-[58px] w-[150px] flex items-center justify-center border border-text50 rounded-md p-2">
            <span class="font-h4 font-medium text-text90 text-nowrap">{{ pageSize }} de {{ collectionSize }}
                items</span>
        </div>
    </div>

    <div class="md:col-span-1 lg:col-span-2 xl:col-span-8 gap-5">
        <div class="flex items-end justify-end">
            <nav class="w-auto">
                <ul class="flex items-center justify-between gap-5">

                    <button [disabled]="currentPage === 1" (click)="changePage(1)"
                        class="w-6 h-6 flex items-center justify-center text-primary75 hover:text-primaryDark disabled:text-text70">
                        <span class=" material-symbols-outlined">
                            keyboard_double_arrow_left
                        </span>
                    </button>

                    <button [disabled]="currentPage === 1" (click)="changePage(currentPage - 1)"
                        class="w-6 h-6 flex items-center justify-center text-primary75 hover:text-primaryDark disabled:text-text70">
                        <span class="material-symbols-outlined">
                            keyboard_arrow_left
                        </span>
                    </button>

                    <ng-container *ngFor="let page of pagesToDisplay; let i = index">
                        <button *ngIf="page !== '...'; else ellipsisTemplate" [disabled]="currentPage === page"
                            (click)="changePage(page)"
                            class="w-6 h-6 flex text-tb2 font-bold items-center justify-center text-primary disabled:text-white disabled:bg-secondary hover:text-white hover:bg-secondaryDark rounded-md">
                            {{ page }}
                        </button>
                        <ng-template #ellipsisTemplate>
                            <span class="w-6 h-6 flex items-center justify-center text-primary">...</span>
                        </ng-template>

                    </ng-container>


                    <button [disabled]="currentPage === pages"
                        class="w-6 h-6 flex items-center justify-center text-primary hover:text-primaryDark disabled:text-text70"
                        (click)="changePage(currentPage + 1)">
                        <span class="material-symbols-outlined">
                            keyboard_arrow_right
                        </span>
                    </button>

                    <button [disabled]="currentPage === pages"
                        class="w-6 h-6 flex items-center justify-center text-primary hover:text-primaryDark disabled:text-text70"
                        (click)="changePage(pages)">
                        <span class="material-symbols-outlined">
                            keyboard_double_arrow_right
                        </span>
                    </button>
                </ul>
            </nav>
        </div>
    </div>
</div>