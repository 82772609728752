<nav class="items-center p-6 bg-white">
  <div class=" lg:grid lg:grid-cols-12 md:flex md:grid-cols-6 sm:flex sm:grid-cols-3 gap-4">

    <!-- Menú hamburguesa -->
    <div class="col-span-1 relative inline-block text-center justify-center">
      <button (click)="toggleMenu()" class="text-primary">
        <span class="material-symbols-outlined text-primary">menu</span>
      </button>

      <!-- Contenido del dropdown -->
      <div *ngIf="isMenuOpen" class="absolute py-3 left-10 top-100 mt-10 w-60 bg-white rounded-lg shadow-lg z-50">
        <div class="px-4 text-left flex justify-between">
          <p class="h2 text-secondaryDark font-bold">Grupo PDC</p> <button class="tag text-primary75">Cambiar</button>
        </div>

        <ul class="text-left">
          <div class="py-4 px-4 tb2 font-bold text-text90 flex justify-between" (click)="toggleCompany()">
            Mis empresas <span class="material-symbols-outlined items-end">{{ companyExpand }}</span>
          </div>
          <ng-container *ngIf="isCompanyOpen">
            <li class="menu-vikingo" routerLink="/fonts" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">brand_family</span> Fuentes
            </li>
            <li class="menu-vikingo" routerLink="/colors" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">palette</span> Paleta de colores
            </li>
            <li class="menu-vikingo" routerLink="/buttons" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">buttons_alt</span> Botones
            </li>
            <li class="menu-vikingo" routerLink="/inputs" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">input</span> Inputs
            </li>
          </ng-container>
          <div class="py-4 px-4 tb2 font-bold text-text90 flex justify-between" (click)="toggleProducts()">
            Mis productos <span class="material-symbols-outlined items-end">{{ productsExpand }}</span>
          </div>
          <ng-container *ngIf="isProductsOpen">
            <ul class="text-left">
              <div class="py-4 px-4 tb1 font-regular text-text90 flex justify-between" (click)="toggleProducts()">
                <span class="material-symbols-outlined text-secondary mr-1">responsive_layout</span>
                Catálogos
                <span class="material-symbols-outlined items-end">{{ productsExpand }}</span>
              </div>
              

              <div class="py-4 px-4 tb1 font-regular text-text90 flex justify-between" (click)="toggleProducts()">
                <span class="material-symbols-outlined text-secondary mr-1">responsive_layout</span>
                FFA
                <span class="material-symbols-outlined items-end">{{ productsExpand }}</span>
              </div>

            </ul>
          </ng-container>

          <div class="py-4 px-4 tb2 font-bold text-text90 flex justify-between" (click)="toggleProcedures()">
            Mis Gestiones <span class="material-symbols-outlined items-end">{{ proceduresExpand }}</span>
          </div>
          <ng-container *ngIf="isProceduresOpen">
            <li class="menu-vikingo" routerLink="/fonts" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">responsive_layout</span> Roles
            </li>
            <li class="menu-vikingo" routerLink="/colors" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">assignment_ind</span> Usuarios
            </li>
            <li class="menu-vikingo" routerLink="/buttons" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">free_cancellation</span> Bitácora
            </li>
            <li class="menu-vikingo" routerLink="/inputs" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">responsive_layout</span> Diccionario de datos
            </li>
            <li class="menu-vikingo" routerLink="/inputs" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">folder</span> Archivos
            </li>
            <li class="menu-vikingo" routerLink="/inputs" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">notifications</span> Notificaciones
            </li>
            <li class="menu-vikingo" routerLink="/inputs" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">how_to_reg</span> Aprobaciones
            </li>
            <li class="menu-vikingo" routerLink="/inputs" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">language</span> Lenguaje
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <div class="col-span-8 justify-left gap-2">
      <img src="assets/images/logo.png" alt="Vikingo Logo" class="h-6 mr-2 cursor-pointer" (click)="onCallIndex()" />
    </div>

    <div class="col-span-3 flex items-end gap-10">
      <button class="w-fit">
        <span class="material-symbols-outlined text-text90">search</span>
      </button>
      <button class="w-fit">
        <span class="material-symbols-outlined text-text90">help</span>
      </button>
      <button class="w-fit">
        <span class="material-symbols-outlined text-text90">notifications</span>
      </button>
      <div class="relative inline-block text-end">
        <button (click)="toggleDropdown()" class="flex items-center space-x-3">
          <div class="bg-info text-white rounded-lg w-8 h-8 flex items-center justify-center">
            <span class="font-h4 text-lg">J</span>
          </div>
          <span class="font-h4 font-medium text-text90 text-nowrap">José Castañaza</span>
          <span class="material-symbols-outlined text-text90">expand_more</span>
        </button>

        <div *ngIf="isDropdownOpen" class="absolute right-0 mt-10 w-60 bg-white rounded-lg shadow-lg">
          <ul class="text-left">
            <li class="menu-vikingo" routerLink="/inputs" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">settings</span> Gestionar cuenta
            </li>
            <li class="menu-vikingo" routerLink="/inputs" (click)="toggleMenu()">
              <span class="material-symbols-outlined mr-1">logout</span> Cerrar sesión
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</nav>