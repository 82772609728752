<div class="grid-cols-12 gap-8">
    <label class="px-3 text-sm font-medium text-primaryDark mb-2">{{label}}</label>

    <div class="flex h-[42px] pl-5 border border-text70 rounded-lg hover:border-primaryDark hover:text-primaryDark">
        <div class="flex items-center w-full">
            <input #calendarInput class="text-primaryDark placeholder-text70 -mr-16" placeholder="dd/mm/aaaa"
                (click)="toggleCalendar($event)" [name]="nameInput" autocomplete="off">
        </div>
        <div class="w-[60px] h-[40px] bg-text40 rounded-e-lg flex items-center justify-center"
            (click)="toggleCalendar($event)">
            <span [ngClass]="{'my-disabled-icon': disabled}"
                class="material-symbols-outlined calendar-icon">calendar_month</span>
        </div>

    </div>
</div>

<div [@openClose]="isOpened ? 'open' : 'closed'" class="my-calendar-container">
    <label *ngIf="selectedStartDate" class="label-date py-2">{{ formatDate(selectedStartDate, selectedEndDate)
        }}</label>
    <div class="my-calendar-controls-container">
        <div style="display: flex;">
            <div class="select_box" [ngClass]="{'select-open': isOpenMonth}">
                <select style="width: 110px;" class="my-calendar-select" (ngModel)="selectedMonth"
                    (change)="handleMonthChange($event)" (focus)="toggleSelectStateMonth(true)"
                    (blur)="toggleSelectStateMonth(false)">
                    <option *ngFor="let month of months; let i = index" [value]="i">{{ month }}</option>
                </select>
            </div>
            <div class="select_box" [ngClass]="{'select-open': isOpenYear}">
                <select class="my-calendar-select" (ngModel)="selectedYear" (change)="handleYearChange($event)"
                    (focus)="toggleSelectStateYear(true)" (blur)="toggleSelectStateYear(false)">
                    <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                </select>
            </div>
        </div>
        <div class="flex">
            <div class="calendar-icon-container" (click)="navigate(-1)">
                <span class="material-symbols-outlined calendar-icon">navigate_before</span>
            </div>
            <div class="calendar-icon-container" (click)="navigate(1)">
                <span class="material-symbols-outlined calendar-icon">navigate_next</span>
            </div>
        </div>
    </div>
    <div class="my-calendar-body-container">
        <div class="calendar-row days-of-week">
            <div class="calendar-week-day" *ngFor="let day of weekDays">{{ day }}</div>
        </div>
        <div class="calendar-row" *ngFor="let week of weeks; let weekIndex = index">
            <div class="calendar-day" *ngFor="let day of week; let dayIndex = index" [ngClass]="{
               'calendar-day-other-month': isOpened && isOtherMonthDay(day, weekIndex),
               'calendar-day-today': isToday(day, weekIndex, dayIndex),
               'calendar-day-selected': isSelectedDay(day, weekIndex, dayIndex) && !isRangeDay(day, weekIndex, dayIndex) && !isStartOrEndDay(day, weekIndex, dayIndex),
               'calendar-day-range': isRangeDay(day, weekIndex, dayIndex),
               'calendar-day-start-end': isStartOrEndDay(day, weekIndex, dayIndex)}"
                (click)="day !== null ? selectDay(day, weekIndex, dayIndex) : null">
                {{ day || ' ' }}
            </div>
        </div>
    </div>

    <ng-container *ngIf="dateTime && !multiselect">
        <div class="p-2 d-flex justify-content-center">
            <ngb-timepicker (ngModel)="selectedTime" [spinners]="false">
            </ngb-timepicker>
        </div>
    </ng-container>
</div>