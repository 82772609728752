import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  public pages: number = 0;
  public totalItems: number = 100;
  public isDropdownOpen: Boolean = false;

  /* Total of items */
  @Input() collectionSize = 100;

  /* Total of items per page */
  @Input() pageSize = 25;

  /* Current page */
  @Input() currentPage = 1;

  /* Amount of buttons to show */
  @Input() maxSize = 3;

  /* Enable boundaries links */
  @Input() boundaryLinks = true;

  /* Enable direction links */
  @Input() directionLinks = true;

  /* Data table componente reference */
  @Input() tableComponent: DatatableComponent | undefined;

  /* Change page event */
  @Output() pageEvent: EventEmitter<any> = new EventEmitter();

  /* Change page size event */
  @Output() limitEvent: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.pages = Math.ceil(this.collectionSize / this.pageSize);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['collectionSize'] || changes['pageSize']) {
      this.pages = Math.ceil(this.collectionSize / this.pageSize);
    }
  }

  get pagesToDisplay(): (number | string)[] {
    const maxPagesToShow = 5;
    const pagesArr: (number | string)[] = [];

    if (this.pages <= maxPagesToShow) {
      return Array.from({ length: this.pages }, (_, i) => i + 1);
    }

    pagesArr.push(1);

    const start = Math.max(2, this.currentPage - 1);
    const end = Math.min(this.pages - 1, this.currentPage + 1);

    if (start > 2) pagesArr.push('...');
    for (let i = start; i <= end; i++) {
      pagesArr.push(i);
    }
    if (end < this.pages - 1) pagesArr.push('...');

    pagesArr.push(this.pages);

    return pagesArr;
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  changeItems(items: number): void {
    this.pageSize = items;
    this.changePageLimit(items);
    this.pages = Math.ceil(this.collectionSize / this.pageSize);
    this.toggleDropdown();
  }

  changePage(page: any): void {
    if (this.tableComponent != null) {
      if (page !== this.tableComponent.offset && page !== '...') {
        this.tableComponent.offset = page - 1;
      } else {
        this.tableComponent.offset = page - 1
      }
    } else {
      if (page !== '...') {
        this.pageEvent.emit(page);
      }
    }
  }

  changePageLimit(limit: number): void {
    if (this.tableComponent != null) {
      this.tableComponent.limit = limit;
    } else {
      this.limitEvent.emit(limit);
    }
  }
}
